@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
body{
    width: 100%;
    font-family: 'Exo', sans-serif;
    overflow-y: auto;
    background: #ffffff;
    overflow-x: hidden;
    position: relative;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
    .btn_white { 
      @apply  border-white bg-white px-8 py-3 text-green-50
    }
    .btn_white_text  { 
      @apply  border-white bg-white px-8 py-3 text-gray-90
    }
    .btn_green  { 
      @apply  border-green-50 bg-green-50 px-8 py-5 text-white
    }
    .btn_dark_green { 
      @apply  bg-green-90 px-8 py-4 text-white transition-all hover:bg-black
    }
    .btn_dark_green_outline  { 
      @apply border-gray-20 bg-green-90 px-8 py-5 text-white
    }
    
    .max-container {
      @apply mx-auto max-w-[1440px];
    }
  
    .padding-container {
      @apply px-3 md:px-20 3xl:px-36 lg:px-20 ;
    }
  
    .flexCenter {
      @apply flex items-center justify-center;
    }
  
    .flexBetween {
      @apply flex items-center justify-between;
    }
  
    .flexStart {
      @apply flex items-center justify-start;
    }
  
    .flexEnd {
      @apply flex items-center justify-end;
    }
  
    /* FONTS */
    .regular-64 {
      @apply text-[64px] font-[400] leading-[120%];
    }
  
    .regular-40 {
      @apply text-[40px] font-[400] leading-[120%];
    }
  
    .regular-32 {
      @apply text-[32px] font-[400];
    }
  
    .regular-24 {
      @apply text-[24px] font-[400];
    }
  
    .regular-20 {
      @apply text-[20px] font-[400];
    }
  
    .regular-18 {
      @apply text-[18px] font-[400];
    }
  
    .regular-16 {
      @apply text-[16px] font-[400];
    }
  
    .regular-14 {
      @apply text-[14px] font-[400];
    }
  
    .medium-14 {
      @apply text-[14px] font-[600];
    }
  
    .bold-88 {
      @apply text-[88px] font-[700] leading-[120%];
    }
  
    .bold-64 {
      @apply text-[64px] font-[700] leading-[120%];
    }
  
    .bold-52 {
      @apply text-[52px] font-[700] leading-[120%];
    }
  
    .bold-40 {
      @apply text-[40px] font-[700] leading-[120%];
    }
  
    .bold-32 {
      @apply text-[32px] font-[700] leading-[120%];
    }
  
    .bold-20 {
      @apply text-[20px] font-[700];
    }
  
    .bold-18 {
      @apply text-[18px] font-[700];
    }
  
    .bold-16 {
      @apply text-[16px] font-[700];
    }
  
    .bold-10 {
      @apply text-[14px] font-[700];
    }
    /* Hero */
    .hero-map {
      @apply absolute right-0 top-0 h-screen w-screen bg-pattern-2 bg-cover bg-center md:-right-28 xl:top-44;
    }
  
    /* Camp */
    .camp-quote {
      @apply absolute -right-6 bottom-4 w-[140px] lg:bottom-10 xl:-right-8 xl:w-[186px] 3xl:right-0;
    }
  
    /* Feature */
    .feature-phone {
      @apply absolute top-[13%] z-10 hidden max-w-[1500px] rotate-[15deg] md:-left-16 lg:flex  3xl:left-20;
    }
  
    /* Get App */
    .get-app {
      @apply max-container relative flex w-full  flex-col justify-between gap-32 overflow-hidden bg-green-90 bg-pattern bg-cover bg-center bg-no-repeat px-6 py-12 text-white sm:flex-row sm:gap-12 sm:py-24 lg:px-20 xl:max-h-[598px] 2xl:rounded-5xl;
    }
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .programme{
    background-image: url("../src/assets/filleBold.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }


  .alune{
    background-image: url("../src/assets/feature-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card{
    background-image: url("../src/assets/pattern.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .accueil{
    background-image: url("../src/assets/modelp.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .about{
    background-image: url("../src/assets/modela.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .don{
    background-image: url("../src/assets/models.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .contact{
    background-image: url("../src/assets/model1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  